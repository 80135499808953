import { GetServerSidePropsContext } from 'next'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useTranslation } from 'react-i18next'
import HeadMetaTags from '~/components/HeadMetaTags'
import configuration from '~/configuration'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { initUrqlClient } from '~/core/hooks/use-initialize-client'
import SimpleCareersContainer from '~/features/careers/[id]'
import QueryCareersSetting from '~/lib/features/careers/[id]/graphql/query-careers-setting'
import { CareerPageSettingType } from '~/lib/features/careers/[id]/types'
import { withTranslationProps } from '~/lib/next/with-translation'

function SimpleCareerPage({
  careerPageSetting
}: {
  careerPageSetting: CareerPageSettingType
}) {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (careerPageSetting?.ga_measurement_id && !ReactGA.isInitialized) {
      ReactGA.initialize(careerPageSetting?.ga_measurement_id)
    }
  }, [careerPageSetting])

  return (
    <>
      <HeadMetaTags
        title={careerPageSetting.page_title}
        description={careerPageSetting.description?.[i18n.language]}
        url={`${PUBLIC_APP_URL}${careerPageSetting.canonical_url}`}
        image={`${PUBLIC_APP_URL}/img/feature/careers/opengraph_v2.jpg`}
      />

      <SimpleCareersContainer careerPageSetting={careerPageSetting} />
    </>
  )
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const { props: translationProps } = await withTranslationProps({
    locale: ctx.locale
  })

  const { urqlClient } = initUrqlClient(
    configuration.api.graphQL,
    undefined,
    ctx.locale
  )

  // call episodes query here
  const careerPageSettingResults = await urqlClient
    .query(QueryCareersSetting, {
      tenantSlug: ctx.query.id
    })
    .toPromise()

  if (careerPageSettingResults.error) {
    return {
      redirect: {
        permanent: false,
        destination: configuration.path.error404
      }
    }
  }

  if (
    !!careerPageSettingResults.data?.publicSettingsCareerSite.languages &&
    !careerPageSettingResults.data?.publicSettingsCareerSite.languages?.[
      ctx.locale || 'en'
    ]?.enable
  ) {
    return {
      redirect: {
        permanent: false,
        destination: configuration.path.error404
      }
    }
  }

  return {
    props: {
      ...translationProps,
      careerPageSetting: careerPageSettingResults.data?.publicSettingsCareerSite
    }
  }
}

export default SimpleCareerPage

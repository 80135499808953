import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import LongContentDisplay from '~/components/LongContentDisplay'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { Avatar } from '~/core/ui/Avatar'
import { Container } from '~/core/ui/Container'
import {
  TypographyH1,
  TypographyH2,
  TypographyH3,
  TypographyH4
} from '~/core/ui/Heading'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import QueryTenant from '~/lib/features/careers/[id]/graphql/query-tenant'
import {
  CareerPageSettingType,
  TenantType
} from '~/lib/features/careers/[id]/types'
import CareerList, { CareerSimpleJobCountId } from './CareerList'

const SimpleCareersView: React.FC<{
  careerPageSetting: CareerPageSettingType
}> = ({ careerPageSetting }) => {
  const { t, i18n } = useTranslation()
  const [tenant, setTenant] = useState<TenantType>()
  const { clientGraphQL } = useContextGraphQL()
  const { query } = useRouter()

  const fetchTenant = useCallback(() => {
    return clientGraphQL
      .query(QueryTenant, {
        tenantSlug: query.id
      })
      .toPromise()
      .then(
        (result: {
          error: string
          data: { publicTenantsShow: TenantType }
        }) => {
          if (result.error) {
            return false
          }

          const { publicTenantsShow } = result.data
          setTenant(publicTenantsShow)

          return publicTenantsShow
        }
      )
  }, [clientGraphQL, query.id])
  const handleScrollToJobList = useCallback(() => {
    const JobListElement = document.querySelector(`#${CareerSimpleJobCountId}`)

    if (JobListElement) {
      JobListElement.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    fetchTenant()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container
      overrideClass={`h-full min-h-[calc(100vh_-_73px)] max-w-[1440px] bg-[url('/img/feature/careers/banner_hero_mobile.png')] tablet:bg-[url('/img/feature/careers/banner_hero_desktop.png')] bg-no-repeat bg-contain`}>
      <div className="m-auto max-w-[804px] px-4 pb-12 pt-8 tablet:px-0 tablet:pb-[139px] tablet:pt-[92px]">
        <div className="mb-4 flex justify-center tablet:mb-5">
          <Avatar
            className="hidden tablet:flex"
            size="3xl"
            shape="rounded"
            defaultAvatar={false}
            src={tenant?.logoVariants?.thumb?.url}
          />
          <Avatar
            className="tablet:hidden"
            size="lg"
            shape="rounded"
            defaultAvatar={false}
            src={tenant?.logoVariants?.thumb?.url}
          />
        </div>
        <TypographyH1 className="mb-6 hidden text-center font-semibold text-gray-900 dark:text-gray-300 tablet:block">
          {careerPageSetting.page_title}
        </TypographyH1>

        <TypographyH3 className="mb-6 text-center text-gray-900 dark:text-gray-300 tablet:hidden">
          {t('careers:discoverNextMove')} <br />
          <span className="text-uppercase">{tenant?.name}</span>
        </TypographyH3>
        <div className="mb-8 text-center tablet:mb-[140px]">
          {tenant?.openJobsCount && tenant?.openJobsCount > 0 ? (
            <>
              <TypographyText className="mr-1 contents text-base text-gray-600 dark:text-gray-300">
                {t('careers:wantToJoinOurTeam')}{' '}
              </TypographyText>
              <TextButton
                size="lg"
                className="contents"
                onClick={handleScrollToJobList}
                label={`${t('careers:jobOpeningNoTag', {
                  number: tenant?.openJobsCount
                })}`}
              />
            </>
          ) : (
            <TypographyText className="text-base text-gray-600 dark:text-gray-300">
              {t('careers:currentlyDontHaveOpenJobs')}
            </TypographyText>
          )}
        </div>
        {careerPageSetting?.description?.[i18n.language] && (
          <div className="mb-8 tablet:mb-[140px] tablet:flex">
            <TypographyH2 className="mr-[124px] hidden whitespace-nowrap text-gray-900 dark:text-gray-300 tablet:block">
              {t('careers:whoWeAre')}
            </TypographyH2>

            <TypographyH4 className="mb-2 whitespace-nowrap text-gray-900 dark:text-gray-300 tablet:hidden">
              {t('careers:whoWeAre')}
            </TypographyH4>
            <LongContentDisplay
              limitLines={4}
              content={
                typeof careerPageSetting?.description === 'object'
                  ? careerPageSetting?.description[i18n.language]
                  : careerPageSetting?.description
              }
              className="flex-1 whitespace-pre-wrap text-base text-gray-600"
              textButtonProps={{ size: 'md' }}
            />
          </div>
        )}
        <TypographyH2 className="mb-10 hidden text-center text-gray-900 dark:text-gray-300 tablet:block">
          {t('careers:newOpportunities')}
        </TypographyH2>

        <TypographyH4 className="mb-4 text-gray-900 dark:text-gray-300 tablet:hidden">
          {t('careers:newOpportunities')}
        </TypographyH4>

        <CareerList
          tenantSlug={query.id}
          careerPageSetting={tenant?.careerSiteSettings}
        />
      </div>
    </Container>
  )
}

export default SimpleCareersView

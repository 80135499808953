import { useState } from 'react'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { IPromiseSearchOption, ISelectOption } from '~/core/ui/Select'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import QueryTenantDepartment from '../graphql/query-tenant-department'
import { IDepartment } from '../types'
import QueryCareerDepartments from '~/lib/features/careers/[id]/graphql/query-career-departments'
import { IRouterWithID } from '~/core/@types/global'

const useDepartment = () => {
  const { clientGraphQL } = useContextGraphQL()
  const [countDepartment, setCountDepartment] = useState(0)

  const promisePublicDepartmentOptions = (
    params = {} as IPromiseSearchOption & { tenantSlug?: IRouterWithID }
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryCareerDepartments, params)
        .toPromise()
        .then((result: IResponseContextResult<IDepartment>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { publicDepartmentsList } = result.data
          const collection = publicDepartmentsList?.collection || []
          const metadata = publicDepartmentsList?.metadata || {}

          const cloneData = collection.map((item: IDepartment) => {
            return {
              value: item.id,
              supportingObj: {
                name: item.name
              }
            }
          })
          setCountDepartment(cloneData?.length)
          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseDepartmentOptions = (
    params = {} as IPromiseSearchOption & { parentId?: number }
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTenantDepartment, params)
        .toPromise()
        .then((result: IResponseContextResult<IDepartment>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { tenantDepartmentsList } = result.data
          const collection = tenantDepartmentsList?.collection || []
          const metadata = tenantDepartmentsList?.metadata || {}

          const cloneData = collection.map((item: IDepartment) => {
            return {
              value: item.id,
              supportingObj: {
                name: item.name
              }
            }
          })
          setCountDepartment(cloneData?.length)
          return resolve({ metadata, collection: cloneData })
        })
    })

  const promisePublicDepartmentMultiLevelOptions = (
    params = {} as IPromiseSearchOption & { tenantSlug?: IRouterWithID }
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryCareerDepartments, params)
        .toPromise()
        .then((result: IResponseContextResult<IDepartment>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { publicDepartmentsList } = result.data
          const collection = publicDepartmentsList?.collection || []
          const metadata = publicDepartmentsList?.metadata || {}

          const cloneData = collection.map((item: IDepartment) => {
            return {
              id: item.id,
              name: item.name,
              openJobsCount: item.openJobsCount,
              parentId: item.parentId,
              subordinates: item.subordinates
            }
          })

          const newCloneData = [] as Array<ISelectOption>
          cloneData.forEach((item) => {
            newCloneData.push({
              value: String(item.id),
              parentId: undefined,
              supportingObj: {
                name: item.name || ''
              }
            })

            if (item.subordinates?.length) {
              item.subordinates.forEach((sub) => {
                newCloneData.push({
                  value: String(sub.id),
                  parentId: String(item.id),
                  supportingObj: {
                    name: sub.name || ''
                  }
                })
              })
            }
          })
          return resolve({ metadata, collection: newCloneData })
        })
    })

  const promiseDepartmentMultiLevelOptions = (
    params = {} as IPromiseSearchOption
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTenantDepartment, params)
        .toPromise()
        .then((result: IResponseContextResult<IDepartment>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }
          const { tenantDepartmentsList } = result.data
          const collection = tenantDepartmentsList?.collection || []
          const metadata = tenantDepartmentsList?.metadata || {}

          const cloneData = collection.map((item: IDepartment) => {
            return {
              id: item.id,
              name: item.name,
              openJobsCount: item.openJobsCount,
              parentId: item.parentId,
              subordinates: item.subordinates
            }
          })

          const newCloneData = [] as Array<ISelectOption>
          cloneData.forEach((item) => {
            newCloneData.push({
              value: String(item.id),
              parentId: undefined,
              supportingObj: {
                name: item.name || ''
              }
            })

            if (item.subordinates?.length) {
              item.subordinates.forEach((sub) => {
                newCloneData.push({
                  value: String(sub.id),
                  parentId: String(item.id),
                  supportingObj: {
                    name: sub.name || ''
                  }
                })
              })
            }
          })
          return resolve({ metadata, collection: newCloneData })
        })
    })

  return {
    promisePublicDepartmentOptions,
    promiseDepartmentOptions,
    promiseDepartmentMultiLevelOptions,
    promisePublicDepartmentMultiLevelOptions,
    countDepartment
  }
}
export default useDepartment

import { gql } from 'urql'

const QueryCareerLocations = gql<
  {
    publicLocationsList: {
      collection: {
        name: string
        address: string
        country: string
        state: string
      }[]
    }
  },
  { page?: number; limit?: number; tenantSlug: string; search?: string }
>`
  query ($page: Int, $limit: Int, $tenantSlug: String!, $search: String) {
    publicLocationsList(
      tenantSlug: $tenantSlug
      page: $page
      limit: $limit
      search: $search
    ) {
      collection {
        name
        address
        country
        state
        countryStateId
      }
    }
  }
`

export default QueryCareerLocations

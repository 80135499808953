import { gql } from 'urql'

const QueryCareerDepartments = gql<
  {
    publicDepartmentsList: {
      collection: Array<{ id: number; name: string }>
      metadata: { totalCount: number }
    }
  },
  { tenantSlug: string; page?: number; limit?: number; search?: string }
>`
  query ($tenantSlug: String!, $page: Int, $limit: Int, $search: String) {
    publicDepartmentsList(
      tenantSlug: $tenantSlug
      page: $page
      limit: $limit
      search: $search
    ) {
      collection {
        id
        name
        openJobsCount
        parentId
        subordinates {
          id
          name
          parentId
          openJobsCount
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryCareerDepartments

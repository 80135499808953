import { useRouter } from 'next/router'
import { useCallback } from 'react'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import QueryCareerLocations from '../graphql/query-career-location'
import { CareerLocationsParams, LocationItem } from '../types'

export const useCareer = () => {
  const { query } = useRouter()
  const { clientGraphQL } = useContextGraphQL()

  const promiseLocationsOptions = useCallback(
    (params = {} as CareerLocationsParams) =>
      clientGraphQL
        .query(QueryCareerLocations, {
          ...params,
          tenantSlug: String(query.id)
        })
        .toPromise()
        .then((result: IResponseContextResult<LocationItem>) => {
          if (result.error) {
            return {
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            }
          }

          const { publicLocationsList } = result.data
          const collection = publicLocationsList?.collection || []
          const metadata = publicLocationsList?.metadata || {}

          const cloneData = collection.map((location) => {
            return {
              id: location.countryStateId,
              value: String(location.state),
              label: location.country,
              supportingObj: {
                name: `${location.state}, ${location.country}`
              }
            }
          })

          return { metadata, collection: cloneData }
        }),
    [clientGraphQL, query.id]
  )

  return {
    promiseLocationsOptions
  }
}

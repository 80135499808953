import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Empty from '~/core/ui/Empty'
import { TypographyText } from '~/core/ui/Text'
import {
  CareersListInfinityProps,
  ICareer
} from '~/lib/features/careers/[id]/types'
import { CareerListPageSize } from './CareerList'

const CareersListInfinity: FC<CareersListInfinityProps> = ({
  emptyConfig,
  dataQuery,
  search,
  listConfig
}) => {
  const { t } = useTranslation()
  const flatData = useMemo(
    () =>
      dataQuery.data?.pages?.flatMap(
        (page: { data: Array<ICareer> }) => page.data
      ) ?? [],
    [dataQuery.data]
  )
  const totalFetched = flatData.length

  const mergedEmptyConfig = {
    ...(emptyConfig || {}),
    show:
      dataQuery.isFetching === false &&
      dataQuery.isLoading === false &&
      dataQuery?.data?.pages?.[0]?.data?.length === 0
  }

  const isSearchEmpty =
    mergedEmptyConfig.show &&
    Object.values(search?.filter || {}).filter((item) => !!item).length > 0

  return mergedEmptyConfig.show === false ? (
    <>
      {dataQuery?.data?.pages?.map((pageArr: { data: Array<ICareer> }) => {
        return pageArr.data.map((item) => listConfig?.renderItem(item))
      })}

      {dataQuery.isFetchedAfterMount &&
      isSearchEmpty === false &&
      dataQuery.data.pages?.[0]?.meta?.totalRowCount > CareerListPageSize ? (
        <>
          {dataQuery.data?.pages?.[0]?.meta?.totalRowCount !== totalFetched ? (
            <div
              className="mt-4 flex cursor-pointer items-center justify-center tablet:mt-6"
              onClick={() => {
                if (dataQuery.fetcher?.fetchNextPage) {
                  dataQuery.fetcher.fetchNextPage()
                }
              }}>
              {listConfig.renderButton}
            </div>
          ) : (
            <TypographyText className="mt-4 text-center text-base text-gray-500">
              {t('careers:thatAllJobs')}
            </TypographyText>
          )}
        </>
      ) : null}
    </>
  ) : (
    <div className={mergedEmptyConfig.classNameEmpty}>
      <Empty
        onClick={() => {
          if (mergedEmptyConfig.buttonTitleOnClick) {
            mergedEmptyConfig.buttonTitleOnClick()
          }
          if (mergedEmptyConfig.buttonTitleSearchOnClick) {
            mergedEmptyConfig.buttonTitleSearchOnClick()
          }
        }}
        type={isSearchEmpty ? 'empty-search' : 'empty-data'}
        title={
          isSearchEmpty
            ? mergedEmptyConfig.titleSearch
            : mergedEmptyConfig.title
        }
        description={
          isSearchEmpty
            ? mergedEmptyConfig.descriptionSearch
            : mergedEmptyConfig.description
        }
        buttonTitle={
          isSearchEmpty
            ? mergedEmptyConfig.buttonTitleSearch
            : mergedEmptyConfig.buttonTitle
        }
      />
    </div>
  )
}

export default CareersListInfinity

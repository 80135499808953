import { TFunction } from 'i18next'
import { z } from 'zod'

const schemaCareerSearch = (t: TFunction) => {
  return z.object({
    search: z.string().optional(),
    location: z
      .array(
        z.object({
          value: z.string(),
          label: z.string(),
          supportingObj: z.object({ name: z.string() })
        })
      )
      .optional(),
    departmentIds: z
      .array(
        z.object({
          value: z.string(),
          supportingObj: z.object({ name: z.string() })
        })
      )
      .optional(),
    remoteStatusOption: z
      .array(
        z.object({
          value: z.string(),
          supportingObj: z.object({ name: z.string() })
        })
      )
      .optional()
  })
}

export default schemaCareerSearch

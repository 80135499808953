import withQueryClientProvider from 'src/hoc/with-query-client-provider'
import SimpleCareersView from '~/components/Careers/[id]'
import { LANG_ENG_OPTION, LANG_JA_OPTION } from '~/components/DropdownLanguage'
import LayoutFooterPowerBy from '~/components/Layout/LayoutFooterPowerBy'
import { CareerPageSettingType } from '~/lib/features/careers/[id]/types'

const SimpleCareersContainer = ({
  careerPageSetting
}: {
  careerPageSetting: CareerPageSettingType
}) => {
  return (
    <LayoutFooterPowerBy
      showSwitchLanguages={
        Object.values(careerPageSetting?.languages || {}).filter(
          (lang) => !!lang.enable
        ).length > 1
      }
      languagesOptions={[LANG_ENG_OPTION, LANG_JA_OPTION]}>
      <SimpleCareersView careerPageSetting={careerPageSetting} />
    </LayoutFooterPowerBy>
  )
}

export default withQueryClientProvider(SimpleCareersContainer)
